<template>
  <div class="box">
    <div class="main-title-box">
      <h1 class="main-title" style="font-size: 20px">My Sourcing Quotations</h1>
      <!-- <div v-if="role === 'attendee'" class="btn clearbtn2" @click="addIt">
        Inquire Sourcing
      </div> -->
    </div>
    <div class="pd-20">
      <div class="cards">
        <Spin v-if="loading" fix></Spin>
        <div class="empty" v-if="list.length === 0">
          No Data
        </div>
        <div v-else>
          <div class="list-table">
            <Table :columns="sourcingCol" :data="list">
              <template slot-scope="{ row: item }" slot="action">
                <Button
                  @click="$router.push(`/sourcing/${item.id}`)"
                  size="small"
                  style="padding: 0 5px;color: #f57c00;border: 1px solid #f57c00;"
                  ><Icon type="md-eye"
                /></Button>
                <Button
                  @click="$router.push(`/sourcing/${item.id}/edit`)"
                  type="warning"
                  size="small"
                  style="margin-left: 5px;padding: 0 5px;"
                  ><Icon type="md-brush"
                /></Button>
                <Button
                  type="warning"
                  size="small"
                  @click="removeSourcing(item)"
                  style="margin-left: 5px;padding: 0 5px;"
                  ><Icon type="md-trash"
                /></Button>
              </template>
            </Table>
          </div>

          <div class="page">
            <Page
              transfer
              :page-size="limit"
              :total="total"
              show-elevator
              show-total
              @on-change="pageChange"
              @on-page-size-change="pageSizeChange"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";
import { uniq, trim } from "lodash";
const { _queryMySourcing, _removeSourcing } = api;

export default {
  name: "products",
  computed: {
    ...mapState("user", ["role"])
  },
  data() {
    return {
      loginPop: false,
      confLoading: false,
      showInquire: false,
      inquireForm: {
        product_name: "-"
      },
      required: [
        {
          required: true,
          message: "Please fill"
        }
      ],
      industry_usage_grade: [
        "Food Grade",
        "Feed Grade",
        "Industrial Grade",
        "Cosmetic Grade"
      ],
      industry_usage_grade_temp: "",
      compendial_standard: ["USP", "FCC", "JP", "CP"],
      compendial_standard_temp: "",
      curItem: {},
      modType: "card",
      page: 1,
      limit: 10, // must in 5-20
      total: 0,
      list: [],
      loading: false,
      sourcingCol: [
        // {
        //   type: "index",
        //   width: 60,
        //   align: "center"
        // },
        {
          title: "Company",
          render: (h, params) => {
            return h(
              "span",
              (params.row.company && params.row.company.company_name) || "--"
            );
          }
        },
        {
          title: "Product Name",
          width: 320,
          key: "product_name"
        },
        {
          title: "Quality Standard",
          width: 200,
          render: (h, params) => {
            return h("span", params.row.quality_standard || "--");
          }
        },
        {
          title: "Spec",
          width: 250,
          render: (h, params) => {
            return h("span", params.row.spec || "--");
          }
        },
        // {
        //   title: "STATUS",
        //   width: 160,
        //   slot: "status"
        // },
        {
          title: "Action",
          width: 150,
          align: "center",
          slot: "action"
        }
      ],
      cateList: [],
      countryList: [],
      filter: {
        product_name: ""
      },
      prices: ["CIF", "FOB", "Delivered", "Others"],
      price_type: "",
      justShowInquire: false
    };
  },
  created() {
    this.getMySourcings();
  },

  methods: {
    removeSourcing(item) {
      this.$Modal.confirm({
        loading: true,
        title: "Confirm Delete",
        content: "Are you sure to delete this item?",
        onOk: () => {
          _removeSourcing({
            id: item.id
          })
            .then(() => {
              this.$Modal.remove();
              this.$Message.success("success");
              this.getSourcings(); // refresh
            })
            .catch(({ message }) => {
              this.$Modal.remove();
              this.$Message.error(message);
            });
        }
      });
    },
    clearSearch() {
      this.page = 1;
      this.curBar = "";
      this.filter.product_name = "";
      this.getSourcings();
    },

    handleSearch() {
      this.page = 1;
      this.getSourcings();
    },

    pageChange(page) {
      this.page = page;
      this.getSourcings();
    },

    pageSizeChange(limit) {
      this.limit = limit;
      this.getSourcings();
    },
    getMySourcings() {
      this.loading = true;
      this.list = [];
      _queryMySourcing({
        page: this.page,
        limit: this.limit,
        ...this.filter
      })
        .then(response => {
          const { data } = response;
          this.list = data.data;

          this.total = data.total;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    delIt(item) {
      this.delPop = true;
      this.curItem = item;
    },
    addIt() {
      this.showInquire = true;
      this.inquireForm.product_name = "";
    },
    editIt(item) {
      this.showInquire = true;
      this.curItem = item;
      this.inquireForm.product_name = item.product_name;
      this.inquireForm.product_type = item.product_type;
      this.inquireForm.country = item.country;
      this.inquireForm.state = item.state;
      this.inquireForm.industry_usage_grade = item.industry_usage_grade;
      this.inquireForm.compendial_standard = item.compendial_standard;

      // industry_usage_grade
      var copyIndustry = JSON.parse(
        JSON.stringify([
          "Food Grade",
          "Feed Grade",
          "Industrial Grade",
          "Cosmetic Grade"
        ])
      );
      if (item.industry_usage_grade) {
        var i = 0;
        item.industry_usage_grade.forEach(ite => {
          if (copyIndustry.indexOf(ite) < 0) {
            i++;
            copyIndustry.push(ite);
            this.industry_usage_grade = copyIndustry;
          }

          if (i === 0) {
            this.industry_usage_grade = copyIndustry;
          }
        });
      } else {
        this.industry_usage_grade = copyIndustry;
      }

      // compendial_standard
      var copyCompendial = JSON.parse(
        JSON.stringify(["USP", "FCC", "JP", "CP"])
      );
      if (item.compendial_standard) {
        var j = 0;
        item.compendial_standard.forEach(ite => {
          if (copyCompendial.indexOf(ite) < 0) {
            j++;
            copyCompendial.push(ite);
            this.compendial_standard = copyCompendial;
          }

          if (j === 0) {
            this.compendial_standard = copyCompendial;
          }
        });
      } else {
        this.compendial_standard = copyCompendial;
      }
    },
    addCheckBox(key) {
      const value = trim(this[`${key}_temp`]);
      if (value) {
        const arr = uniq([...this[key], value]);
        this[key] = arr;
        this.industry_usage_grade_temp = "";
        this.compendial_standard_temp = "";
      }
    },
    cancel() {
      this.loading = false;
      this.handleReset("form");
      this.industry_usage_grade_temp = "";
      this.compendial_standard_temp = "";
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    showIt(item) {
      this.showInquire = false;
      this.justShowInquire = true;
      this.curItem = item;
      this.inquireForm.product_name = item.product_name;
      this.inquireForm.product_type = item.product_type;
      this.inquireForm.country = item.country;
      this.inquireForm.state = item.state;
      this.inquireForm.industry_usage_grade = item.industry_usage_grade;
      this.inquireForm.compendial_standard = item.compendial_standard;
      this.inquireForm.quantity = item.quantity;
      this.inquireForm.quality_standard = item.quality_standard;
      this.inquireForm.application = item.application;
      this.inquireForm.main_specs = item.main_specs;
      this.inquireForm.price_basis = item.price_basis;
      this.inquireForm.price_basis_value = item.price_basis_value;
      this.inquireForm.packaging = item.packaging;
      this.inquireForm.shipment_date = item.shipment_date;
      this.inquireForm.price_idea = item.price_idea;
      this.inquireForm.required_documents = item.required_documents;
      this.inquireForm.note = item.note;
      this.inquireForm.fob_basis = item.fob_basis;
      this.inquireForm.price_indication = item.price_indication;
      this.inquireForm.shipment_date = item.shipment_date;
      this.inquireForm.spec = item.spec;
      this.inquireForm.price_indication = item.price_indication;
      this.inquireForm.packaging = item.packaging;
      this.price_type = item.price_basis ? item.price_basis : "";
    }
  }
};
</script>

<style lang="less" scoped>
.box {
  background: #ffffff;
  margin: 60px auto 0 auto;
}
.main-title-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 20px;
  border-bottom: 1px solid #ccc;
  .clearbtn2 {
    cursor: pointer;
    margin-left: 190px;
    padding: 2px 20px;
    color: #fff;
    line-height: 30px;
    background-color: @primary-color;
    border: 1px solid @primary-color;
    border-radius: 6px;
  }
  .extra {
    .total {
      color: #333;
      .des {
        font-size: 16px;
      }
      .num {
        font-weight: bold;
        font-size: 18px;
      }
    }
  }
}

.search-box {
  display: flex;
  align-items: center;
  /*border-radius: 50px;*/
  height: 35px;
  overflow: hidden;
  width: 45%;
  margin: 20px;
  .input-box {
    position: relative;
    flex: 1;
    height: 100%;
    background: #fff;
    line-height: initial;
    /*padding-left: 20px;*/
    /*padding-right: 20px;*/
    /*border: 1px solid #dfdfdf;*/
    /*border-right: none;*/
    /*border-top-left-radius: 40px;*/
    /*border-bottom-left-radius: 40px;*/
    border: 1px solid #ef7f21;
    border-radius: 5px;
    padding-left: 3px;
    padding-right: 3px;
    .search-icon {
      position: absolute;
      top: 7px;
      left: 10px;
      font-size: 20px;
      color: #888;
    }
    input {
      width: 100%;
      height: 100%;
      outline: none;
      background: transparent;
      border: none;
      color: #444;
      caret-color: #888;

      &::-webkit-input-placeholder {
        color: #bdcada;
      }
      &:-moz-placeholder {
        color: #bdcada;
      }
      &::-moz-placeholder {
        color: #bdcada;
      }
      &:-ms-input-placeholder {
        color: #bdcada;
      }
    }
  }
  .btn {
    cursor: pointer;
    background: #fff;
    color: @primary-color;
    border-radius: 5px;
    /*display: flex;*/
    align-items: center;
    justify-self: center;
    line-height: initial;
    height: 100%;
    margin-left: 20px;
    background: #ef7f21;
    font-size: 18px;

    font-weight: 500;
    color: #ffffff;
    line-height: 18px;
    text-align: center;
    /*padding-top: 9px;*/
    width: 120px;
    height: 35px;
  }
  .my-sourcing {
    width: 200px;
    height: 30px;
    background: linear-gradient(90deg, #f5a210, #ef8126);
    border-radius: 5px;
    font-size: 16px;

    font-weight: 400;
    color: #ffffff;
    line-height: 18px;
    text-align: center;
  }
  .clearbtn {
    margin-left: 10px;
    color: #666;
    border: 1px solid #ddd;
    border-radius: 20px;
  }
  .type-wrap {
    position: absolute;
    right: 20px;
    color: #444;
    font-size: 12px;
    text-align: right;
    .des {
      display: block;
    }
    .num {
      font-weight: bold;
      font-size: 14px;
    }
  }
}

// /deep/ .list-table .ivu-table-body .ivu-btn {
//   color: @primary-color;
//   border: 1px solid @primary-color;
// }

.sourcing {
  .ivu-form-item {
    margin-bottom: 15px;
  }
  .checkbox-box-input {
    width: initial;
    .ivu-input {
      border: 1px solid #ccc !important;
    }
  }
  .form-box-title {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    color: #515a6e;
    line-height: 1;
    padding: 10px 12px 10px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .compose {
    display: inline-block;
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 6px;
  }
  .form-title-xing {
    justify-content: initial !important;
  }
  .form-title-xing:before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-size: 14px;
    color: #ed4014;
  }
}

/deep/ .ivu-table-wrapper {
  overflow: inherit;
}

.search {
  background: #fff;
  display: flex;
  padding: 20px;
  border-bottom: 1px solid #ccc;
  .search-input {
    width: 400px;
  }
}

.box {
  .empty {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cards {
    min-height: 300px;
    position: relative;
    .item {
      margin-bottom: 20px;
      border: 1px solid #ccc;
      border-radius: 6px;
      background: #fff;
      box-shadow: 3px 3px 6px #ddd;
      .img-box {
        width: 100%;
        overflow: hidden;
        border-bottom: 1px solid #ccc;
        padding-bottom: 20px;
        .wrap {
          min-width: 40px;
          height: 40px;
          margin: 15px auto 10px;
          overflow: hidden;
          text-align: center;
          .avatar {
            height: 100%;
          }
        }
        .cname {
          width: 100%;
          text-align: center;
          font-size: 13px;
          color: #444;
          font-weight: bold;
        }
      }

      .name {
        display: flex;
        border-bottom: 1px solid #ccc;
        color: #666;
        .com-name {
          flex: 0 0 50%;
          padding: 10px 0;
          padding-left: 20px;
          font-size: 12px;
          color: #888;
          small {
            color: #000;
          }
        }
        .product-name {
          border-right: 1px solid #ccc;
        }
      }
      .user {
        padding: 10px;
        text-align: center;
        .user-name {
          font-size: 12px;
          color: @primary-color;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .tags {
        padding: 10px;
        height: 50px;
        display: flex;
        align-items: center;
        overflow: hidden;
        .tag {
          color: #fff;
          border-radius: 3px;
          font-size: 12px;
          margin-right: 4px;
          height: 25px;
          display: flex;
          align-items: center;
          padding: 0 5px;
          font-weight: bold;
        }
      }
    }
    .page {
      margin: 10px 0;
      display: flex;
      justify-content: flex-end;
    }

    .ivu-poptip-body {
      h4 {
        font-size: 12px;
        text-align: left;
        color: #444;
        padding: 4px 0;
      }
      .tags {
        flex-wrap: wrap;
        display: flex;
        p {
          text-align: left;
          color: #999;
          flex: 0 0 50%;
          padding: 4px 0;
        }
      }
    }
  }
}
</style>
<style lang="less">
.ivu-table thead {
  background: linear-gradient(90deg, #a25d72, #2d3b81);
  opacity: 0.9;
}
.ivu-table th {
  background: none;
  font-size: 18px;

  font-weight: 500;
  color: #fefefe;
  line-height: 19px;
}
.list-table {
  width: 1200px;
  overflow-x: auto;
  white-space: nowrap;
}
.pd-20 {
  overflow-x: auto;
  white-space: nowrap;
}
</style>
